<template>
  <div class="myCollect">
      <div class="header">
        <div class="title">我的收藏</div>
        <div class="search">
          <el-input size="small" placeholder="输入产品名称" v-model="searchValue" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item,index) in collectList" @click="goGoodsDetail(item)" :key="index">
          <div class="cover">
            <img :src="item.commodityImage">
          </div>
          <div class="name">{{item.goodsCondition}} | {{item.name}}</div>
          <div class="config">{{item.defaultPropertiesShow}}</div>
          <div class="price">￥{{item.minimumRent}}/月</div>
        </div>
      </div>
  </div>
</template>
<script>
import {checkAllCollect} from '@/api/category/index'
export default {
  name:'myCollect',
  data(){
    return{
      collectList:[],
      searchValue:''
    }
  },
  created() {
    this.getCollectList()
  },
  methods:{
    getCollectList(){
      checkAllCollect({current:1,size:100}).then(res=>{
        if(res.data.code == 200){
          this.collectList = res.data.data.records
        }
      })
    },
    goGoodsDetail(item){
      if(item.commodityId && item.commodityId != -1){
        this.$router.push({
          path:'/details',
          query:{commodityId:item.commodityId}
        })
      }else {
        this.$message.error('商品错误')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.myCollect{
  padding: 10px;
  font-size: 16px;
  background: #ffffff;
  .header{
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eee;
    padding: 20px;
    .title{
      font-size: 20px;
      width: 70%;
      margin-right: 20px;
    }
    .search{
      flex: 1;
    }
  }
  .list{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 220px;
      height: 250px;
      padding: 10px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      cursor: pointer;
      .cover{
        img{
          width: 150px;
          height: 150px;
        }
      }
      .name{
        font-size: 18px;
        font-weight: bold;
      }
      .config ,.name{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
     }
    .price{
      color: red;
    }
  }
}
</style>