
<template>
  <div class="applyInvoice">
    <el-dialog title="申请开票" width="500px"  @close="closeModel" :visible.sync="applyShow">
      <el-form :model="invoiceForm" size="small" :rules="rules" ref="invoiceForm" label-width="120px">
        <el-form-item  label="抬头类型">
          企业单位
        </el-form-item>
        <el-form-item  label="发票类型">
          <el-radio-group @input="changeType"  v-model="invoiceForm.billType">
            <el-radio  label="1">普票</el-radio>
            <el-radio  label="2">专票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票抬头">
          武汉问道供应链管理有限公司
        </el-form-item>
        <el-form-item label="税号">
          {{invoiceForm.ird || '无'}}
        </el-form-item>
        <el-form-item label="发票内容">
          电脑租赁和服务器
        </el-form-item>
        <el-form-item prop="emailAddress" label="邮箱地址">
          <el-input  placeholder="请输入邮箱地址" v-model="invoiceForm.emailAddress" clearable></el-input>
        </el-form-item>
        <el-form-item prop="registeredAddress" label="注册地址">
          <el-input  placeholder="请输入注册地址" v-model="invoiceForm.registeredAddress" clearable></el-input>
        </el-form-item>
        <el-form-item prop="registeredPhone" label="注册电话">
          <el-input  placeholder="请输入注册电话" v-model="invoiceForm.registeredPhone" clearable></el-input>
        </el-form-item>
        <el-form-item prop="accountOpenBank" label="开户银行">
          <el-input  placeholder="请输入开户银行" v-model="invoiceForm.accountOpenBank" clearable></el-input>
        </el-form-item>
        <el-form-item prop="bankAccount" label="银行账号">
          <el-input  placeholder="请输入银行账号" v-model="invoiceForm.bankAccount" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeModel">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {validatePhone} from "@/util/validator";
import {applyForInvoice} from "@/api/order";

export default {
  name:'applyInvoice',
  data(){
    return{
      applyShow:false,
      invoiceForm:{},
      rules:{
      }
    }
  },
  methods:{
    showModel(){
      this.applyShow = true
    },
    closeModel(){
      this.invoiceForm = {}
      this.applyShow = false
    },
    changeType(value){
      this.rules = {
        emailAddress: [{ required: true, trigger: 'blur', message: '请输入邮箱地址'}],
        registeredPhone: [{ required: value == 2, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
        registeredAddress: [{ required: value == 2, trigger: 'blur', message: '请输入注册地址'}],
        accountOpenBank: [{ required: value == 2, trigger: 'blur', message: '请输入开户银行'}],
        bankAccount: [{ required: value == 2, trigger: 'blur', message: '请输入银行账号'}],
      }
    },
    submit(){
      this.$refs.invoiceForm.validate(valid=>{
        if(valid){
          applyForInvoice(this.invoiceForm).then(res=>{
            if(res.data.code == 200){
              this.$message.success('申请已提交，请耐心等待')
              this.$emit('confirm')
              this.closeModel()
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>

</style>