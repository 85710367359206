<template>
<div class="myAccount" id="myAccount">
  <div class="model">
    <div class="title">基础信息</div>
    <div class="userInfo">
      <div class="avatar"><img src="@/assets/imgs/cover.jpg"></div>
      <div class="info">
        <div class="item">
          <div class="label">用户名：</div>
          <div class="content">{{userInfo.userName}}</div>
<!--          <el-button type="text">修改用户名</el-button>-->
        </div>
        <div class="item">
          <div class="label">账号密码：</div>
          <div class="content">********</div>
          <el-button type="text" @click="editPass">修改</el-button>
        </div>
        <div class="item">
          <div class="label">绑定手机号：</div>
          <div class="content">{{userInfo.mobile}}</div>
          <el-button type="text" @click="editPhone">更换手机号</el-button>
        </div>
        <div v-if="userInfo.isEnterpriseUser" class="item">
          <div class="label">绑定企业：</div>
          <div class="content">{{enterpriseList.length? enterpriseList[0].enterpriseName:'暂无企业'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="userInfo.isEnterpriseUser" class="model">
    <div class="title">我的企业</div>
    <div v-if="enterpriseList.length" class="enterpriseList">
      <div class="enterprise"  v-for="(item,index) in enterpriseList" @click="goEnterpriseInfo(item)" :key="index">
        <div class="enterpriseName">{{item.enterpriseName}}</div>
        <div class="detail">
          <div class="item"><div class="label">统一社会信用代码：</div><div class="value">{{item.socialCreditCode}}</div></div>
          <div class="item"><div class="label">法人：</div><div class="value">{{item.legalPerson}}</div></div>
          <div class="item"><div class="label">法人身份证号：</div><div class="value">{{item.legalPersonIdCardNumber}}</div></div>
          <div class="item"><div class="label">法人手机号：</div><div class="value">{{item.legalPersonPhone}}</div></div>
          <div class="item"><div class="label">法人联系地址：</div><div class="value">{{item.legalPersonIdCardAddress}}</div></div>
        </div>
        <div class="btn">
          <el-button  type="text">查看协议</el-button>
        </div>
      </div>
    </div>
    <div v-else class="entry">
      暂无企业
    </div>
  </div>
  <div class="model">
    <div class="title">收货地址</div>
    <div class="address">
      <div class="item" v-for="(item,index) in deliverList" :key="index">
        <div class="row">{{item.deliveryName}}</div>
        <div class="row">{{item.deliveryPhone}}</div>
        <div class="row">{{item.province +item.city+item.area+item.detailAddress}}</div>
        <div class="row btn">
          <el-button type="text" @click="addAddress('edit',item)">修改</el-button>
          <el-button type="text" danger @click="delAddress(item)">删除</el-button>
        </div>
      </div>
      <div class="item addAddressInfo" @click="addAddress('add',null)">
        <div class="iconfont icon icon-jiahao-dange"></div>
        <div class="empty">新增地址</div>
      </div>
    </div>
  </div>
  <el-dialog title="修改密码" width="500px"  @close="closePass" :visible.sync="passwordModel">
    <el-form :model="passForm" size="small" :rules="rules" ref="passForm" label-width="120px">
      <el-form-item prop="password" label="原密码">
        <el-input prefix-icon="el-icon-lock" placeholder="请设置6-18位密码" v-model="passForm.password" show-password clearable></el-input>
      </el-form-item>
      <el-form-item prop="newPassword" label="新密码">
        <el-input prefix-icon="el-icon-lock" placeholder="请设置6-18位密码" v-model="passForm.newPassword" show-password clearable></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input prefix-icon="el-icon-lock" placeholder="请再次确定密码" v-model="confirmPass" show-password clearable></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="手机号">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="passForm.mobile" clearable></el-input>
      </el-form-item>
      <el-form-item prop="verCode" label="手机验证码">
        <el-input
            clearable
            prefix-icon="el-icon-view"
            placeholder="请输入验证码"
            v-model="passForm.verCode"
        >
          <el-button slot="append" type="text" @click="getVerifyCode(passForm.mobile,1)" :loading="downCode != 120"><span class="verify">{{downCode ==120 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closePass">取消</el-button>
      <el-button size="small" type="primary" @click="submitPass">确认修改</el-button>
    </div>
  </el-dialog>
  <el-dialog title="更换手机号" width="500px" @close="closeMobile"  :visible.sync="mobileModel">
    <el-form :model="mobileForm" size="small" ref="ruleForm" label-width="120px">
      <template v-if="!nextStep">
      <el-form-item prop="mobile" label="当前手机号">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="mobileForm.oldMobile" clearable></el-input>
      </el-form-item>
      <el-form-item prop="verCode" label="手机验证码">
        <el-input
            clearable
            prefix-icon="el-icon-view"
            placeholder="请输入验证码"
            v-model="mobileForm.oldVerCode"
        >
          <el-button slot="append" type="text" @click="getVerifyCode(mobileForm.oldMobile,2)" :loading="oldDownCode != 120"><span class="verify">{{oldDownCode ==120 ? "获取验证码" :`${oldDownCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="mobile" label="更换手机号">
          <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="mobileForm.newMobile" clearable></el-input>
        </el-form-item>
        <el-form-item prop="verCode" label="手机验证码">
          <el-input
              clearable
              prefix-icon="el-icon-view"
              placeholder="请输入验证码"
              v-model="mobileForm.newVerCode"
          >
            <el-button slot="append" type="text" @click="getVerifyCode(mobileForm.newMobile,3)" :loading="newDownCode != 120"><span class="verify">{{newDownCode ==120 ? "获取验证码" :`${newDownCode}s` }}</span></el-button>
          </el-input>
        </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeMobile">取消</el-button>
      <el-button v-if="!nextStep" size="small" type="primary" @click="step">下一步</el-button>
      <el-button v-else size="small" type="primary" @click="submitMobile">确认修改</el-button>
    </div>
  </el-dialog>
  <!--地址-->
  <addAddress @confirm="getDeliverList" ref="address"></addAddress>
</div>
</template>
<script>
import {deliveryaddressList,deliveryaddressRemove} from '@/api/deliveryaddress/index'
import {userName, getEnterpriseInfoList, verCode,updatePassword,affirmMobile,updateMobile} from '@/api/userInfoPort/index'
import addAddress from "@/components/addAddress.vue";
import {validatePass, validatePhone} from "@/util/validator";
import { mapActions } from "vuex";
export default {
  name:'myAccount',
  components:{addAddress},
  data(){
    return{
      nextStep:false,
      userInfo:{},
      enterpriseList:[],
      deliverList:[],
      // 密码
      passwordModel:false,
      passForm:{},
      timer: null,
      downCode:120,
      confirmPass:"",
      rules:{
        password: [{ required: true, trigger: 'blur', message: '密码以字母开头,长度在6-18之间,允许字母数字和下划线', validator: validatePass}],
        verCode: [{ required: true, trigger: 'blur', message: '请输入验证码'}],
        mobile: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
      },
      // 手机号
      mobileModel:false,
      mobileForm:{},
      oldTimer:null,
      oldDownCode:120,
      newTimer:null,
      newDownCode:120,
    }
  },
  created() {
    this.getUserDetail()
    this.getEnterpriseList()
    this.getDeliverList()
  },
  methods:{
    ...mapActions(["removeStore"]),
    closePass(){
      this.passwordModel = false
      this.passForm = {}
    },
    closeMobile(){
      this.nextStep = false
      this.mobileModel = false
      this.mobileForm = {}
    },
    submitPass(){
      this.$refs.passForm.validate(valid=> {
        if (valid) {
          if (!this.confirmPass) {
            this.$message.error('请再次确认密码')
            return
          }
          if (this.passForm.newPassword != this.confirmPass) {
            this.$message.error('两次输入密码不一致')
            return;
          }
          updatePassword(this.passForm).then(res => {
            if (res.data.code == 200) {
              this.closePass()
              this.$message.success('修改成功')
              this.removeStore()
              this.$router.push({ path: '/' })
            }
          })
        }
      })
    },
    step(){
      if(!/^1[3-9]\d{9}$/.test(this.mobileForm.oldMobile)){
        this.$message.error('请输入正确的手机号！')
        return
      }
      if(!this.mobileForm.oldVerCode){
        this.$message.error('请输入验证码！')
        return
      }
      affirmMobile({mobile:this.mobileForm.oldMobile,verCode:this.mobileForm.oldVerCode}).then(res=>{
        if(res.data.code == 200){
          this.nextStep = true
        }
      })
    },
    submitMobile(){
      if(!/^1[3-9]\d{9}$/.test(this.mobileForm.newMobile)){
        this.$message.error('请输入正确的手机号！')
        return
      }
      if(!this.mobileForm.newVerCode){
        this.$message.error('请输入验证码！')
        return
      }
      updateMobile({mobile:this.mobileForm.oldMobile,verCode:this.mobileForm.oldVerCode}).then(res=>{
        if(res.data.code == 200){
          this.closeMobile()
          this.removeStore()
          this.$router.push({ path: '/' })
          this.nextStep = true
        }
      })
    },
    // 修改密码
    editPass(){
      this.passwordModel = true
    },
    // 修改手机号
    editPhone(){
      this.mobileModel = true
    },
    addAddress(type,info){
      this.$nextTick(()=>this.$refs.address.openDialog(type,info))
    },
    delAddress(info){
      this.$confirm("是否确定删除该地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        deliveryaddressRemove({id:info.id}).then(res=>{
          if(res.data.code == 200){
            this.$message.success('删除成功')
            this.getDeliverList()
          }
        })
      })
    },
    getVerifyCode(mobile,type){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(mobile)){
        verCode({mobile}).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            switch (type){
              case 1:
                this.timer =  setInterval(()=>{
                  this.downCode--
                  if(this.downCode==0){
                    this.downCode = 120
                    clearInterval(this.timer)
                  }
                },1000)
                break
              case 2:
                this.oldTimer =  setInterval(()=>{
                  this.oldDownCode--
                  if(this.oldDownCode==0){
                    this.oldDownCode = 120
                    clearInterval(this.oldTimer)
                  }
                },1000)
                break
              case 3:
                this.newTimer =  setInterval(()=>{
                  this.newDownCode--
                  if(this.newDownCode==0){
                    this.newDownCode = 120
                    clearInterval(this.newTimer)
                  }
                },1000)
                break
              default :
                break
            }

          }else{
            this.$message.warning(data.msg)
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号！')
      }
    },
    getUserDetail(){
      userName().then(res=>{
          if(res.data.code == 200){
            this.userInfo = res.data.data
          }
        })
    },
    getEnterpriseList(){
      getEnterpriseInfoList().then(res=>{
        if(res.data.code == 200){
          this.enterpriseList = res.data.data
        }
      })
    },
    goEnterpriseInfo(info){
      console.log(info)
      this.$router.push({path:'/EnterpriseInfo'})
    },
    getDeliverList(){
      deliveryaddressList().then(res=>{
        if(res.data.code == 200){
          this.deliverList = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.myAccount{
  padding: 10px;
  font-size: 14px;
  color: #5e5e5e;
  .model{
    margin-bottom: 20px;
    padding: 0 20px;
    .title{
      font-size: 16px;
      margin: 10px 0;
    }
  }
  .userInfo{
    display: flex;
    height: 200px;
    .avatar{
      margin-right: 30px;
      img{
        width: 200px;
        height: 200px;
      }
    }
    .info{
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      height: 80%;
      justify-content: space-between;
      .item{
        display: flex;
        align-items: center;
        .content{
          margin: 0 10px;
          font-size: 16px;
          color: #1b1f23;
        }
      }
    }
  }
  .enterpriseList{
    display: flex;
    flex-wrap: wrap;
  }
  .enterprise{
    width: 400px;
    padding: 10px;
    border: 2px solid #adb3b9;
    margin-right: 20px;
    cursor: pointer;
    .enterpriseName{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
    .detail{
      .item{
        margin-bottom: 10px;
        display: flex;
        .label{
        }
      }
    }
    .btn{
      text-align: right;
      ::v-deep .el-button{
        padding: 0;
      }
    }
  }
  .address{
    display: flex;
    flex-wrap: wrap;
    .item{
      padding:10px;
      border: 2px solid #adb3b9;
      width: 250px;
      margin-right: 20px;
      .row{
        margin-bottom: 10px;
        break-word: break-all;
      }
      .btn{
        text-align: right;
        margin-bottom: 0;
        ::v-deep .el-button{
          padding: 0;
        }
      }
    }
  }
  .addAddressInfo{
    cursor: pointer;
    border: 2px solid #adb3b9;
    border-top-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    .icon{
      color: #6a737d;
      font-size: 60px;
    }
    .empty{
      color: #6a737d;
      font-size: 16px;
    }
  }
}
</style>