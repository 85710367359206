
<template>
<div class="myBill">
  <div class="header">
    <div class="title">我的账单</div>
  </div>
  <div class="info">
    <div class="left">
      <div class="label item">对公转账信息</div>
      <div class="item">开户名称：武汉问道供应链管理有限公司</div>
      <div class="item">开户行：平安银行股份有限公司武汉百步亭支行</div>
      <div class="item">账号：15059690080052</div>
    </div>
    <div class="right">
      <div class="top">
        总计应还：￥{{payInfo.allPay || '0.00'}}
      </div>
      <div class="center">
        (本月应还：￥{{payInfo.currentMonthPay|| '0.00'}} 逾期账单：{{payInfo.overdueFine|| '0.00'}} 滞纳金：￥{{payInfo.overduePay|| '0.00'}})
      </div>
      <div class="btn">
<!--        <el-button size="small" type="primary">立即还款</el-button>-->
      </div>
    </div>
  </div>
  <div class="detail">
    <div class="search">
      <div class="left">
        <div class="title">账单明细</div>
        <el-input size="small" placeholder="输入订单号" v-model="orderNumber" class="input-with-select">
          <el-button @click="getBillList" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="right">
        <el-select size="small" v-model="billType">
          <el-option label="逾期账单" value="1"></el-option>
          <el-option label="本月待还" value="2"></el-option>
<!--          <el-option label="还款记录" value="3"></el-option>-->
        </el-select>
      </div>
    </div>
    <div class="table">
      <el-table size="small" border :data="tableData" :header-cell-style="{'text-align':'center', 'background': '#f5f5f5'}" :cell-style="{'text-align':'center'}" style="width: 100%">
        <el-table-column prop="name" label="期数">
          <template slot-scope="scope">
            第{{scope.row.leaseTerm}}期
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" width="200" label="账单归属订单"></el-table-column>
        <el-table-column prop="name" label="应付金额">
          <template slot-scope="scope">
            ￥{{scope.row.leasingAmount}}
          </template>
        </el-table-column>
        <el-table-column width="200" prop="billDate" label="应付日期"></el-table-column>
        <el-table-column prop="name" label="账单状态">
          <template slot-scope="scope">
            {{scope.row.installmentState == 0?'待还款':scope.row.installmentState == 1?'已逾期':'已结清'}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="滞纳金">
          <template slot-scope="scope">
            ￥{{scope.row.leasingAmount}}
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态"></el-table-column>
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="showAllBill(scope.row)">查看全部账单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <allBillList ref="allBillList"></allBillList>
</div>
</template>

<script>
import allBillList from "./allBillList.vue"
import {billList,shouldPay} from '@/api/order/index'
export default {
  name:'myBill',
  components:{allBillList},
  data(){
    return{
      orderNumber:'',
      billType:'1',
      tableData:[],
      payInfo:{}
    }
  },
  created() {
    this.getBillList()
    this.getPayInfo()
  },
  methods:{
    getBillList(){
      billList({billType:this.billType,orderNumber:this.orderNumber}).then(res=>{
        if(res.data.code == 200){
          this.tableData = res.data.data.records
        }
      })
    },
    getPayInfo(){
      shouldPay().then(res=>{
        if(res.data.code == 200){
          this.payInfo = res.data.data
        }
      })
    },
    showAllBill(row){
      this.$nextTick(()=>this.$refs.allBillList.showDialog(row.orderNumber))
    }
  }
}
</script>

<style scoped lang="scss">
.myBill{
  padding: 10px;
  font-size: 16px;
  .header{
    padding: 20px;
    border-bottom: 1px dashed #eee;
  }
  .info{
    margin: 20px 0;
    border: 1px solid #eee;
    padding: 10px;
    display: flex;
    .left{
      width: 50%;
      .item{
        margin: 10px 20px;
      }
      .label{
        margin: 10px;
      }
    }
    .right{
      padding: 10px 0;
      flex: 1;
      border-left: 1px dashed #eee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .center{
        color: red;
      }
    }
  }
  .detail{
    margin: 20px 0;
    .search{
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        display: flex;
        align-items: center;
        .title{
          width: 100px;
        }
      }
    }
  }
}
</style>