
<template>
<div class="allBillList">
  <el-dialog :title="`订单编号：${orderNumber}`" width="80%"  @close="closeDialog" :visible.sync="billShow">
    <el-table size="small" height="400" border :data="billList" :header-cell-style="{'text-align':'center', 'background': '#f5f5f5'}" :cell-style="{'text-align':'center'}" style="width: 100%">
      <el-table-column prop="name" label="期数">
        <template slot-scope="scope">
          第{{scope.row.leaseTerm}}期
        </template>
      </el-table-column>
      <el-table-column width="200" prop="billDate" label="应付日期"></el-table-column>
      <el-table-column prop="name" label="应付金额">
        <template slot-scope="scope">
          ￥{{scope.row.leasingAmount}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="账单状态">
        <template slot-scope="scope">
          {{scope.row.installmentState == 0?'待还款':scope.row.installmentState == 1?'已逾期':'已结清'}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="滞纳金">
        <template slot-scope="scope">
          ￥{{scope.row.leasingAmount}}
        </template>
      </el-table-column>
      <el-table-column prop="payStatus" label="支付状态"></el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeDialog">关闭</el-button>
    </div>
  </el-dialog>
</div>
</template>
<script>
import {checkAllBill} from '@/api/order/index'
export default {
  name:'allBillList',
  data(){
    return{
      billShow:false,
      billList:[],
      orderNumber:''
    }
  },
  methods:{
    showDialog(orderNumber){
      this.orderNumber = orderNumber
      this.initData()
      this.billShow = true
    },
    initData(){
      checkAllBill({orderNumber:this.orderNumber}).then(res=>{
        if(res.data.code == 200){
          this.billList = res.data.data
        }
      })
    },
    closeDialog(){
      this.billShow = false
    }
  }
}
</script>

<style scoped>

</style>