
<template>
  <div class="myInvoice">
    <div class="header">
      <div class="title">我的发票</div>
    </div>
    <div class="detail">
      <div class="search">
        <div class="left">
          <el-radio-group @input="getInvoiceInfoPage"  v-model="invoiceStatus2">
            <el-radio  label="1">可开票账单</el-radio>
            <el-radio  label="3">开票记录</el-radio>
          </el-radio-group>
        </div>
        <div class="right">
          <el-date-picker size="small" value-format="yyyy-MM" format="yyyy-MM" v-model="payDate" type="month" placeholder="选择月"></el-date-picker>
        </div>
      </div>
      <div class="table">
        <el-table size="small" border  @selection-change="handleSelectionChange" :data="tableData" :header-cell-style="{'text-align':'center', 'background': '#f5f5f5'}" :cell-style="{'text-align':'center'}" style="width: 100%">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column width="400" prop="name" label="商品信息">
            <template slot-scope="scope">
              <div class="goodsInfo">
                <div class="item">{{scope.row.orderNumber}}-第{{scope.row.leaseTerm}}期</div>
                <div class="item name">{{scope.row.commodityName}}</div>
                <div class="item">{{scope.row.specifications}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="repaymentTime" label="付款时间"></el-table-column>
          <el-table-column prop="invoiceStatus" label="开票状态">
            <template slot-scope="scope">
              <span :class="scope.row.invoiceStatus == 1?'green':'gray'">{{scope.row.invoiceStatus == 1?'可开票':'已开票'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="可开票金额">
            <template slot-scope="scope">
              ￥{{scope.row.invoiceMoney}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button @click="retry(scope.row)" v-if="scope.row.invoiceStatus == 2 && !scope.row.requestForResendTime" type="text">申请重发</el-button>
              <el-button @click="urge(scope.row)" v-if="scope.row.invoiceStatus == 1 && !scope.row.reminderTime" type="text">催一催</el-button>
              <el-button @click="apply(scope.row)" v-if="scope.row.invoiceStatus == 1" type="text">申请开票</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="merge">
        <div></div>
        <div class="rightBox">
          <div class="label">合计：</div>
          <div class="price"><span class="red">13123</span>元</div>
          <div class="total">共2笔账单</div>
          <el-button type="primary" size="small" @click="allApply">合并开票</el-button>
        </div>
      </div>
    </div>
    <applyInvoice ref="applyInvoice" @confirm="getInvoiceInfoPage"></applyInvoice>
  </div>
</template>

<script>
import applyInvoice from "./applyInvoice.vue"
import {invoiceUrge, regenerateInvoice, selectAllInvoiceInfoPage} from "@/api/order";

export default {
  name:'myInvoice',
  components:{applyInvoice},
  data(){
    return{
      payDate:'',
      invoiceStatus2:'1',
      tableData:[],
      multipleSelection:[]
    }
  },
  created() {
    this.getInvoiceInfoPage()
  },
  methods:{
    getInvoiceInfoPage(){
      selectAllInvoiceInfoPage({invoiceStatus2:this.invoiceStatus2}).then(res=>{
        if(res.data.code == 200){
          this.tableData = res.data.data.records
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    apply(row){
      this.$refs.applyInvoice.showModel(row)
    },
    allApply(){
      if(!this.multipleSelection.length){
        this.$message.error('请选择账单')
      }
    },
    urge(row){
      invoiceUrge({enterpriseInvoiceRecordId:row.id}).then(res=>{
        if(res.data.code == 200){
          this.$message.success('催一催成功')
        }
      })
    },
    retry(row){
      regenerateInvoice({enterpriseInvoiceRecordId:row.id}).then(res=>{
        if(res.data.code == 200){
          this.$message.success('重发成功')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.myInvoice{
  padding: 10px;
  font-size: 16px;
  height: 100%;
  .header{
    padding: 20px;
    border-bottom: 1px dashed #eee;
  }
  .detail{
    height: calc(100% - 110px);
    position: relative;
    margin: 20px 0;
    box-sizing: border-box;
    .search{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed #eee;
      ::v-deep .el-radio__input {  display: none !important;}
      .left{
        width: 50%;
        display: flex;
        align-items: center;
        .title{
          width: 100px;
        }
      }
    }
    .goodsInfo{
      .name{
        margin: 10px 0;
        font-weight: bold;
      }
    }
    .green{
      color: green;
    }
    .gray{
      color: gray;
    }
    .merge{
      position: sticky;
      bottom: 0;
      right: 0;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      color: #6a737d;
      .rightBox{
        display: flex;
        align-items: center;
      }
      .label{
        font-size: 20px;
        color: #000000;
      }
      .price{
        .red{
          color: red;
        }
      }
      .total{
        margin: 0 10px;
      }
    }
  }
}
</style>