<template>
  <div class="detail">
    <h2 @click="orderPage()">
      <i class="el-icon-arrow-left"></i>
      订单详情
    </h2>
    <div class="detail-title">
      <span>订单编号：{{ detailinfo.orderNumber }}</span>
      <span>下单时间：{{ detailinfo.orderCreateTime }}</span>
    </div>
    <div class="detail-info">
      <img class="info-img" :src="detailinfo.skuImage" />
      <div class="info-name">产品名称：{{ detailinfo.commodityInfo }}</div>
      <div class="info-dsc">配置：【{{ detailinfo.specifications }}】</div>
      <div class="info-dsc">数量： {{ detailinfo.commodityNumber }}台</div>
      <div class="info-state">租期：{{ detailinfo.leasePatternName }}</div>
      <div class="info-rent">
        <b>每月租金：</b>
        <span v-if="detailinfo.rent_1_12 * 1 > 0">1-12月租金：{{ detailinfo.rent_1_12 }}元</span>
        <span v-if="detailinfo.rent_13_24 * 1 > 0">13-24月租金：{{ detailinfo.rent_13_24 }}元</span>
        <span v-if="detailinfo.rent_25_36 * 1 > 0">25-36月租金：{{ detailinfo.rent_25_36 }}元</span>
      </div>
      <div class="info-state">备注：{{ detailinfo.leasePatternName }}</div>
      <div class="order-state">
        <h3 v-if="[0, 8, 9].includes(detailinfo.orderState)" style="color: #1890ff;">
          <img src="../../../assets/imgs/ddzt1.png" />
          <span>{{ detailinfo.orderRemake }}</span>
        </h3>
        <h3 v-if="[3, 4, 5, 6].includes(detailinfo.orderState)" style="color: #13c463;">
          <img src="../../../assets/imgs/ddzt2.png" />
          <span>{{ detailinfo.orderRemake }}</span>
        </h3>
        <h3 v-if="[1, 2].includes(detailinfo.orderState)" style="color: #fd9a08;">
          <img src="../../../assets/imgs/ddzt3.png" />
          <span>{{ detailinfo.orderRemake }}</span>
        </h3>
        <h3 v-if="[7].includes(detailinfo.orderState)" style="color: #f45151;">
          <img src="../../../assets/imgs/ddzt4.png" />
          <span>{{ detailinfo.orderRemake }}</span>
        </h3>
      </div>
    </div>
    <div class="detail-message">
      <div class="message-item">
        <h4>收货信息 <el-button @click="editAddress('edit',detailinfo)" type="text" v-if="detailinfo.orderState==0 || detailinfo.orderState==1">修改收货信息</el-button></h4>
        <p>
          <span>收货人：</span>
          {{ detailinfo.deliveryName }}
        </p>
        <p>
          <span>手机号码：</span>
          {{ detailinfo.deliveryPhone }}
        </p>
        <p>
          <span>收货地址：</span>
          {{ detailinfo.deliveryAddress }}
        </p>
      </div>

      <div class="message-item">
        <h4>配送信息</h4>
        <p>
          <span>发货日期：</span>
          {{ detailinfo.deliveryTime ? detailinfo.deliveryTime : detailinfo.expectDeliveryDate + '(预计)' }}
        </p>
<!--        <p>-->
<!--          <span>配送方式：</span>-->
<!--          {{ detailinfo.logisticsCompany }}-->
<!--        </p>-->
        <p>
          <span>配送物流单号：</span>
          {{ detailinfo.logisticsOrderNo }}
        </p>
        <p>
          <span>配送物流：</span>
          <el-link type="primary" @click="dialogVisible = true" v-if="logisticsInfo.length > 0">查看物流</el-link>
        </p>
      </div>
    </div>
    <div class="detail-bill">
<!--      <h4>账单信息</h4>-->
<!--      <el-table :data="tableData" border style="width: 100%">-->
<!--        <el-table-column prop="leaseTerm" label="租期" width="50"></el-table-column>-->
<!--        <el-table-column prop="leaseStartTime" label="账单日" width="180"></el-table-column>-->
<!--        <el-table-column prop="leasingAmount" label="应付租金"></el-table-column>-->
<!--        <el-table-column prop="installmentState" label="支付状态">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.installmentState == 0">未支付</span>-->
<!--            <span v-if="scope.row.installmentState == 1">已逾期</span>-->
<!--            <span v-if="scope.row.installmentState == 2">已支付</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="repaymentTime" label="支付时间"></el-table-column>-->
<!--        <el-table-column prop="address" width="90" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-link v-if="scope.row.installmentState == 0" type="primary" @click="GoPayment(scope.row, scope.row.leaseTerm)">支付租金</el-link>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
      <div class="bill-dsc">
        <el-link class="spanbtn" type="primary" @click="onhelp('PrivacyAggrement')">《巨悠租隐私政策》</el-link>
        <el-link class="spanbtn" type="primary" @click="onhelp('UserAggrement')">《用户信息查询授权书》</el-link>
        <el-link class="spanbtn" type="primary" @click="openURL(detailinfo.leaseServiceAgreement)" v-if="detailinfo.leaseServiceAgreement">《巨悠租租赁服务协议》</el-link>
        <el-link class="spanbtn" type="primary" @click="openURL(detailinfo.contractDownloadUrl)" v-if="detailinfo.contractDownloadUrl">《租赁服务协议》</el-link>
        <el-link class="spanbtn" type="primary" @click="openURL(detailinfo.electronicReceipt)" v-if="detailinfo.electronicReceipt">《巨悠租设备签收单》</el-link>
        <div class="detail-btn">
          <el-button plain @click="cancel" v-if="detailinfo.orderState == 9 || detailinfo.orderState == 8 || detailinfo.orderState == 0 || detailinfo.orderState == 1">取消订单</el-button>
        </div>
      </div>
    </div>

    <el-dialog title="取消原因" :visible.sync="cancelDialog" width="30%" center>
      <el-radio-group v-model="cancelCause">
        <el-radio :label="'太贵了'"></el-radio>
        <el-radio label="商品选错了"></el-radio>
        <el-radio label="不想要了"></el-radio>
        <el-radio label="地址填错了"></el-radio>
        <el-radio label="其他平台已下单"></el-radio>
        <el-radio label="其他"></el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="cancelDialog = false">再想想</el-button>
        <el-button size="small" @click="Cancellation">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="物流轨迹" :visible.sync="dialogVisible" append-to-body width="50%">
      <el-timeline>
        <el-timeline-item v-for="(activity, index) in logisticsInfo" :key="index" :color="'#0bbd87'" :timestamp="activity.AcceptTime">
          {{ activity.AcceptStation }}
        </el-timeline-item>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!--地址-->
    <addAddress @confirm="getOrderdetail" ref="address"></addAddress>
  </div>
</template>
<script>
import {orderCancel, orderDetail} from "@/api/order";
import addAddress from "@/components/addAddress.vue";

export default {
  components: {addAddress},
  props: ['orderDetailId'],
  data() {
    return {
      detailinfo: {},
      tableData: [],
      dialogVisible: false,
      cancelDialog: false,
      logisticsInfo: [],
      cancelCause: '不想要了'
    }
  },
  watch: {},
  created() {
    this.request()
  },
  methods: {
    request() {
      this.getOrderdetail()
      this.getOrderbill()
    },
    editAddress(type,info){
      this.$nextTick(()=>this.$refs.address.openDialog(type,info))
    },
    getOrderdetail() {
      orderDetail(this.orderDetailId).then((res) => {
        if (res.data.code == 200) {
          this.detailinfo = res.data.data
          this.logisticsInfo = this.detailinfo.logisticsInfo ? JSON.parse(this.detailinfo.logisticsInfo).Traces : []
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    getOrderbill() {
      this.$axios.get('/zuwu-api/web/order/bill/' + this.orderDetailId).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.installments
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    GoPayment(val, num) {
      let data = {
        expandParams: {
          leaseTerm: num
        },
        payCause: 1,
        orderId: val.orderId,
        useComputerWebPay: true
      }
      this.$axios.post('/zuwu-api/orderPay/getPayTradeNo', data).then((res) => {
        if (res.data.code == 200) {
          // this.dialogVisible=true
          this.paymentPage = res.data.data.paymentPage
          this.$nextTick(() => {
            // document.write(this.paymentPage,'_blank')
            //  假设result是后端返回的from字符串
            const newWindow = window.open('', '_blank')
            newWindow.document.write(this.paymentPage)
            newWindow.focus()
          })
          //打开新页面
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    Cancellation() {
      let data = {
        cancelCause: this.cancelCause,
        id: this.orderDetailId
      }
      orderCancel(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.cancelDialog = false
          this.request()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    cancel() {
      this.cancelDialog = true
    },
    orderPage() {
      this.$emit('goBack')
    },
    // chakan
    onhelp(name) {
      this.$router.push({ path: '/help/' + name })
    },
    openURL(url) {
      window.open(url)
    }
  }
}
</script>
<style scoped>
.detail {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  color: #333;
}
.detail h2 {
  font-size: 18px;
  font-weight: normal;
  color: #333;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.detail-title {
  margin: 10px;
  overflow: hidden;
}
.detail-title span {
  width: 50%;
  float: left;
  font-size: 14px;
}
.detail-info {
  overflow: hidden;
  position: relative;
  height: 220px;
  padding-left: 20px;
}
.detail-info .info-img {
  width: 210px;
  height: 200px;
  float: left;
  margin-right: 20px;
}
.detail-info .info-name {
  width: 650px;
  line-height: 24px;
  color: #000;
  font-size: 16px;
}
.detail-info .info-dsc {
  margin: 5px 0 5px;
}
.detail-info .info-state {
  margin-bottom: 10px;
}
.detail-info .info-rent {
  overflow: hidden;
  margin-bottom: 10px;
}
.detail-info .info-rent b {
  font-weight: normal;
  display: block;
}
.detail-info .info-rent span {
  line-height: 20px;
  margin-right: 10px;
}
.detail-info .order-state {
  overflow: hidden;
  position: absolute;
  right: 50px;
  top: 0;
  width: 200px;
  height: 160px;
}
.detail-info .order-state img {
  width: 100%;
  height: 100%;
}
.detail-info .order-state h3 {
  text-align: center;
  position: relative;
}
.detail-info .order-state h3 span {
  font-size: 24px;
  text-align: center;
  line-height: 160px;
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-30deg);
}
.detail-message {
  border-bottom: 1px dashed #e7e7e7;
  border-top: 1px dashed #e7e7e7;
  padding: 20px 10px;
  overflow: hidden;
}
.detail-message .message-item {
  width: 47%;
  padding-right: 3%;
  float: left;
}
.detail-message .message-item h4 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
}
.detail-message .message-item p {
  overflow: hidden;
}
.detail-message .message-item p span {
  min-width: 80px;
  margin-bottom: 8px;
  float: left;
}
.detail-bill {
  border-bottom: 1px dashed #e7e7e7;
  padding: 20px 10px;
}
.detail-bill h4 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
}
.detail-bill .bill-dsc {
  margin-top: 20px;
  line-height: 40px;
}
.detail-bill /deep/ .el-table {
  overflow-y: auto;
}
.detail-bill /deep/ .el-table td,
.el-table th {
  padding: 5px 0;
  font-size: 12px;
}
.detail-bill /deep/ .el-table th,
.el-table tr {
  background: #fafafa;
  padding: 5px 0;
}
.detail-btn {
  float: right;
}
.detail /deep/ .el-radio {
  width: 40%;
  margin-top: 10px;
  font-size: 16px;
}
</style>
