<template>
<div class="complaint">
  <div class="header">
    <div>我要投诉</div>
    <div> 投诉热线：13100682026</div>
  </div>
  <div class="main">
    <div class="tips">
      如果你您在服务期间遇到任何问题，请在下方留下您的宝贵意见，我们的工作人员会及时
      给您反馈，给您造成不便还请谅解。
    </div>
    <div class="form">
      <el-form size="small" :model="form" :rules="rules"  ref="ruleForm" label-width="120px">
        <el-form-item label="投诉内容" prop="feedback">
          <el-input type="textarea" :rows="3" placeholder="请输入投诉内容" v-model="form.feedback"></el-input>
        </el-form-item>
        <el-form-item label="上传附件">
          <Upload-item @handleChange="importFile" :file-list="attachImgUrls" @ondelete="onDelete"></Upload-item>
        </el-form-item>
        <el-form-item label="联系人" prop="userName">
          <el-input  placeholder="请输入联系人" v-model="form.userName" clearable></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="联系人电话">
          <el-input  placeholder="请输入联系人电话" v-model="form.phone" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button  type="primary" @click="submit">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</div>
</template>
<script>
import {validatePhone} from "@/util/validator";
import {saveComplaint} from '@/api/serve/index'
export default {
  name:'complaint',
  components:{},
  data(){
    return{
      form:{},
      attachImgUrls:[],
      rules:{
        feedback: [{ required: true, trigger: 'blur', message: '请输入投诉内容'}],
        userName: [{ required: true, trigger: 'blur', message: '请输入联系人'}],
        phone: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
      },
    }
  },
  methods:{
    importFile(file){
      this.attachImgUrls.push(file)
    },
    onDelete(i){
      this.attachImgUrls.splice(i,1)
    },
    submit(){
      this.$refs.ruleForm.validate((valid)=>{
        if(valid) {
          saveComplaint(this.form).then(res=>{
            if(res.data.code == 200){
              this.$message.success('投诉成功')
              this.$emit('select','1')
              this.form = {}
              this.attachImgUrls = []
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.complaint{
  padding: 10px;
  font-size: 16px;
  .header{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #eee;
  }
  .main{
    margin: 20px auto;
    padding: 0 10px;
    .tips{
      color: #777c81;
    }
    .markdown{
      width: 80%;
      min-height: 100px;
      margin: 20px auto;
    }
    .form{
      margin-top: 20px;
      width: 60%;
      .btn{
        text-align: right;
      }
    }
  }
}

</style>