<template>
<div class="personalCenter" id="personalCenter">
  <div class="top">
    <div class="img">
      <img src="@/assets/imgs/cover.jpg">
    </div>
    <div class="info">
      <div>
        <div class="phone">{{userInfo.mobile}}</div>
        <div class="tips">欢迎来到巨悠租~</div>
      </div>
      <el-button type="text" @click="showModel('1-1')">修改个人信息>></el-button>
    </div>
  </div>
  <div class="bottom">
    <div class="item">
      <div class="icon">
        <img src="@/assets/imgs/order.png">
      </div>
      <div class="content">
        <div class="title">
          待支付订单：<span class="count">{{numberInfo.pendingPayCount}}</span>
        </div>
        <div class="look" @click="showModel('2-1')">查看全部订单>></div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img src="@/assets/imgs/car.png">
      </div>
      <div class="content">
        <div class="title">
          待收货订单：<span class="count">{{numberInfo.shippedCount}}</span>
        </div>
        <div class="look" @click="showModel('2-1')">查看全部订单>></div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img src="@/assets/imgs/bill.png">
      </div>
      <div class="content">
        <div class="title">
          本月待还：<span class="count price">￥{{numberInfo.currentMonthPay}}</span>
        </div>
        <div class="look" @click="showModel('2-4')">查看全部账单>></div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img src="@/assets/imgs/collect1.png">
      </div>
      <div class="content">
        <div class="title">
          收藏的商品：<span class="count">{{numberInfo.favoriteCount}}</span>
        </div>
        <div class="look" @click="showModel('2-2')">查看全部收藏>></div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {userName,orderNumber} from '@/api/userInfoPort/index'
export default {
  data(){
    return{
      userInfo:{},
      numberInfo:{}
    }
  },
  created() {
    this.getNumberInfo()
    this.getUserName()
  },
  methods:{
    showModel(key){
      this.$emit('select',key)
    },
    getUserName(){
      userName().then(res=>{
        if(res.data.code == 200){
          this.userInfo = res.data.data
        }
      })
    },
    getNumberInfo(){
      orderNumber().then(res=>{
        if(res.data.code == 200){
          this.numberInfo = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.personalCenter{
  padding: 20px;
  font-size: 16px;
  .top{
    padding: 20px;
    display: flex;
    border-bottom: 1px dashed #eee;
    .img{
      width: 150px;
      height: 150px;
      margin-right: 20px;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .info{
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tips{
        color: #6a737d;
        font-size: 14px;
      }
      .phone{
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .bottom{
    margin-top: 30px;
    padding: 0 20px;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item{
      width: 40%;
      display: flex;
      margin-bottom: 50px;
      cursor: pointer;
      .icon{
        width: 100px;
        height: 100px;
        margin-right: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .content{
        padding: 20px 0;
        height: 80%;
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: center;
        .title{
          margin-bottom: 20px;
        }
        .count{
          color: orange;
        }
        .price{
          color: red;
        }
        .look{
          color: #1b1f23;
          font-size: 14px;
        }
      }
    }
  }
}
</style>