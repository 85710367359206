<template>
<div class="myOrder" id="myOrder">
  <div v-if="!detailShow">
    <div class="header">
      <div class="tabs">
        <el-tabs  v-model="orderQueryState" @tab-click="getOrderList">
          <el-tab-pane v-for="item in tabList" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="search">
        <el-input size="small" placeholder="输入产品名称" v-model="searchValue" class="input-with-select">
          <el-button @click="getOrderList" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="table">
      <el-table size="small" border :data="tableData" :header-cell-style="{'text-align':'center', 'background': '#f5f5f5'}" :cell-style="{'text-align':'center'}" style="width: 100%">
        <el-table-column prop="date" width="500" label="商品信息">
          <template slot-scope="scope">
            <div class="orderInfo" @click="goDetail(scope.row)">
              <div class="img">
                <img :src="scope.row.skuImage">
              </div>
              <div class="info">
                <div class="item">订单编号：{{scope.row.orderNumber}}</div>
                <div class="item name">{{scope.row.productName}}</div>
                <div class="item">{{scope.row.propertyShow}}</div>
                <div class="item">下单时间：{{scope.row.orderTime}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="订单状态">
          <template slot-scope="scope">
            {{tabList.find(item=>item.value == scope.row.orderStateInfo)?tabList.find(item=>item.value == scope.row.orderStateInfo).label : '--' }}
          </template>
        </el-table-column>
        <el-table-column width="300" prop="address" label="租金">
          <template slot-scope="scope">
            <div class="monthRent">
              <p class="month">月租金：</p>
              <div>
                <p v-for="item in scope.row.rentalInfoList" :key="item">{{item}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="数量">
          <template slot-scope="scope">
            {{scope.row.commodityNumber}}台
          </template>
        </el-table-column>
        <el-table-column width="100" prop="address" label="操作">
          <template slot-scope="scope">
<!--            <el-button v-if="scope.row.orderStateInfo == 1" type="text">立即支付</el-button>-->
            <el-button v-if="scope.row.orderState==0 || scope.row.orderState==1" type="text" @click.stop="supplementData(scope.row)">补充资料</el-button>
            <el-button v-if="scope.row.orderState == 8" type="text" @click="getContractUrl(scope.row.orderId)">签署合同</el-button>
            <el-button v-else-if="scope.row.contractDownloadUrl" type="text" @click="downContractUrl(scope.row.contractDownloadUrl)">查看合同</el-button>
            <el-button v-if="scope.row.orderState==0 || scope.row.orderState==1" type="text" @click="cancelOrder(scope.row)">取消订单</el-button>
<!--            <el-button v-if="scope.row.orderStateInfo == 4" type="text">报修</el-button>-->
<!--            <el-button v-if="scope.row.orderStateInfo == 4" type="text">申请退租</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
          prev-text="上一页"
          next-text="下一页"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
  <el-dialog title="取消原因" :visible.sync="cancelDialog" width="30%" center>
    <el-radio-group v-model="cancelCause">
      <el-radio :label="'太贵了'"></el-radio>
      <el-radio label="商品选错了"></el-radio>
      <el-radio label="不想要了"></el-radio>
      <el-radio label="地址填错了"></el-radio>
      <el-radio label="其他平台已下单"></el-radio>
      <el-radio label="其他"></el-radio>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="cancelDialog = false">再想想</el-button>
        <el-button size="small" @click="Cancellation">确 定</el-button>
      </span>
  </el-dialog>
  <div v-if="detailShow" class="detail">
    <newOrderDetail @goBack="detailBack" :orderDetailId = "orderDetailId"></newOrderDetail>
  </div>
</div>
</template>

<script>
import {getContractUrl, orderCancel, orderList} from "@/api/order";
import newOrderDetail from './newOrderDetail.vue'
export default {
  name:'myOrder',
  components:{newOrderDetail},
  data(){
    return{ cancelDialog: false,
      logisticsInfo: [],
      cancelCause: '不想要了',
      orderDetailId:'',
      detailShow:false,
      pageSizes: [10, 20, 30, 50, 100, 1000],
      pageSize:10,
      currentPage:1,
      total:10,
      tableData:[],
      orderQueryState:'1',
      searchValue:'',
      tabList:[
        {
          label:'全部订单',
          value:'0'
        },
        {
          label:'待审核',
          value:'1'
        },
        {
          label:'待发货',
          value:'2'
        },
        {
          label:'待收货',
          value:'3'
        },
        {
          label:'租用中',
          value:'4'
        },
        {
          label:'已完结',
          value:'5'
        },
        {
          label:'退租/售后',
          value:'6'
        },
      ]
    }
  },
  created() {
    this.getOrderList()
  },
  methods:{
    getOrderList() {
      orderList({current:this.currentPage,size:this.pageSize,orderQueryState:this.orderQueryState}).then(res=>{
        if(res.data.code == 200){
          this.tableData = res.data.data.records
          this.total = res.data.data.total
        }
      })
    },
    goDetail(row){
      this.detailShow = true
      this.orderDetailId = row.orderId
    },
    detailBack(){
      this.detailShow = false
    },
    cancelOrder(row){
      this.cancelDialog = true
      this.orderDetailId = row.orderId
    },
    Cancellation() {
      let data = {
        cancelCause: this.cancelCause,
        id: this.orderDetailId
      }
      orderCancel(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.cancelDialog = false
          this.request()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    getContractUrl(orderId) {
      getContractUrl({ orderId: orderId }).then((res) => {
        if (res.data.code == 200) {
          window.open(res.data.data.signUrl, '_blank')
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定'
          })
        }
      })
    },
    downContractUrl(url){
      window.open(url, '_blank')
    },
    supplementData(row){
      if (row.isEnterpriseOrder) {
        this.$router.push({ path: '/Enterprise' })
      } else {
        this.$router.push({ path: '/Collating' })
      }
    },
    handleSizeChange(size){
      this.pageSize = size
      this.getOrderList()

    },
    handleCurrentChange(page){
      this.currentPage = page
      this.getOrderList()
    }
  }
}
</script>

<style scoped lang="scss">
.myOrder{
  padding: 10px;
  font-size: 16px;
  .header{
    display: flex;
    align-items: center;
    .tabs{
      width: 70%;
      margin-right: 20px;
    }
    .search{
      flex: 1;
    }
  }
  .table{
    max-height:700px;
    overflow-y: scroll;
    .orderInfo{
      display: flex;
      cursor: pointer;
      img{
        width: 120px;
        height: 100px;
        margin-right: 20px;
      }
      .info{
        width: 350px;
        text-align: left;
        display: flex;
        flex-direction: column;
       justify-content: center;
      }
    }
    .monthRent{
      display: flex;
      .month{
        width: 60px;
      }
    }
    ::v-deep .el-button{
      margin-left: 0;
      padding: 5px 0;
    }
  }
  .pagination{
    margin-top: 20px;
    text-align: center;
  }
}
</style>