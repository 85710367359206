<template>
<div class="UserCenter" id="UserCenter">
  <MyMenu :menuname="'user'"></MyMenu>
  <div class="main">
    <div class="leftNav">
      <el-menu
          ref="menus"
          @select="handleSelect"
          @close="handleClose"
          :default-openeds="['1','1-2','1-3','2','3','4']"
          :default-active="showKey"
          class="el-menu-vertical-demo">
        <el-submenu index="1">
          <template slot="title">
            <span>用户中心</span>
          </template>
          <el-menu-item index="1-1">
            <span slot="title">我的账户</span>
          </el-menu-item>
          <el-submenu index="1-2">
            <template slot="title">协议说明</template>
            <el-menu-item index="1-2-1">租赁服务协议</el-menu-item>
            <el-menu-item index="1-2-2">框架服务协议</el-menu-item>
            <el-menu-item index="1-2-3">用户信息查询授权书</el-menu-item>
            <el-menu-item index="1-2-4">隐私协议</el-menu-item>
          </el-submenu>
          <el-submenu index="1-3">
            <template slot="title">租赁说明</template>
            <el-menu-item index="1-3-1">其它说明</el-menu-item>
            <el-menu-item index="1-3-2">下单须知</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">订单中心</template>
          <el-menu-item index="2-1">我的订单</el-menu-item>
          <el-menu-item index="2-2">我的收藏</el-menu-item>
          <el-menu-item index="2-3">我的发票</el-menu-item>
          <el-menu-item index="2-4">我的账单</el-menu-item>
        </el-submenu>
<!--        <el-submenu index="3">-->
<!--          <template slot="title">售后服务</template>-->
<!--          <el-menu-item index="3-1">申请售后</el-menu-item>-->
<!--          <el-menu-item index="3-2">售后记录</el-menu-item>-->
<!--        </el-submenu>-->
        <el-submenu index="4">
          <template slot="title">企业概况</template>
          <el-menu-item index="4-1">企业信息</el-menu-item>
        </el-submenu>
        <el-menu-item index="5">
          我要投诉
        </el-menu-item>
      </el-menu>
    </div>
    <div class="rightMain">
      <div class="breadcrumb">
        <div @click="handleSelect('1')">个人中心</div>
      </div>
      <div class="content">
        <!--个人中心-->
        <personalCenter v-if="showKey == '1'" @select="handleSelect"></personalCenter>
        <!--我的账户-->
        <myAccount v-if="showKey == '1-1'"></myAccount>
        <!--投诉-->
        <complaint @select="handleSelect" v-if="showKey == '5'"></complaint>
        <!-- 租赁服务协议 -->
        <div class="preview" v-if="showKey == '1-2-1'">
          <previewPDF :pdfSrc="service" :pageCount="14"></previewPDF>
        </div>
        <!-- 框架服务协议 -->
        <div class="preview" v-if="showKey == '1-2-2'">
          <previewPDF :pdfSrc="frameService" :pageCount="14"></previewPDF>
        </div>
        <!--用户信息授权书-->
        <UserAggrement v-if="showKey == '1-2-3'"></UserAggrement>
        <!-- 隐私协议 -->
        <div class="preview" v-if="showKey == '1-2-4'">
          <previewPDF :pdfSrc="privacyAggrement" :pageCount="14"></previewPDF>
        </div>
        <!-- 租赁说明 -->
        <div class="explain" v-if="showKey == '1-3-1'" style="text-align: center">
          <img src="https://file.juuzu.com/%E7%A7%9F%E8%B5%81%E8%AF%B4%E6%98%8E.jpg" />
        </div>
        <!-- 下单须知 -->
        <div class="notice" v-if="showKey == '1-3-2'" style="text-align: center">
          <img src="https://file.juuzu.com/%E4%B8%8B%E5%8D%95%E9%A1%BB%E7%9F%A5.jpg" />
        </div>
        <!-- 关于我们 -->
        <About v-if="showKey == '4-1'"></About>
        <!--我的订单-->
        <myOrder v-if="showKey == '2-1'"></myOrder>
        <!--我的收藏-->
        <myCollect v-if="showKey == '2-2'"></myCollect>
        <!--我的账单-->
        <myBill v-if="showKey == '2-4'"></myBill>
        <!--我的发票-->
        <myInvoice v-if="showKey == '2-3'"></myInvoice>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import myInvoice from "./components/myInvoice.vue"
import myBill from "./components/myBill.vue"
import myCollect from "./components/myCollect.vue"
import myOrder from "./components/myOrder.vue"
import myAccount from "./components/myAccount.vue"
import personalCenter from './components/personalCenter.vue'
import complaint from "./components/complaint.vue"
import previewPDF from "@/views/Help/item/previewPDF.vue";
import UserAggrement from "@/views/Help/item/UserAggrement.vue";
import About from "@/views/Help/item/About.vue";
export default {
  name:'UserCenter',
  components:{About, UserAggrement, previewPDF, personalCenter,myAccount,complaint,myOrder,myCollect,myBill,myInvoice},
  data(){
    return{
      showKey:"1",
      privacyAggrement:'https://file.juuzu.com/%E5%B7%A8%E6%82%A0%E7%A7%9F%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%20.pdf',
      frameService:'https://file.juuzu.com/%E6%A1%86%E6%9E%B6%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-1.pdf',
      service:'https://file.juuzu.com/%E9%AB%98%E7%93%B4%E7%9B%9B%E7%A7%9F%E8%B5%81%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE20230725.pdf'

    }
  },
  created() {
    if(this.$route.query.key){
      this.handleSelect(this.$route.query.key)
    }
  },
  methods:{
    handleClose(key) {
      this.$refs.menus.open(key);
      // this.handleSelect(key)
    },
    handleSelect(key){
      this.showKey = key
    }
  }
}
</script>

<style scoped lang="scss">
.UserCenter{
  .main{
    max-width: 1430px;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    .leftNav{
      width: 250px;
      margin-right: 30px;
      ::v-deep .el-submenu__title{
        height: 46px;
        line-height: 46px;
      }
      ::v-deep .el-menu-item{
        height: 40px;
        line-height: 40px;
      }
    }
    .rightMain{
      flex: 1;
      .content{
        background: #fff;
        height: calc(100% - 50px);
      }
      .breadcrumb{
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>